<template>
    <div class="header-links">
        <div class="navItem yah">
            <a
                id="nav-link_images"
                class="navLink"
                :href="
                    `https://images.search.yahoo.com/yhs/search?p=${
                        enteredQuery ? enteredQuery : ''
                    }&type=${typeTag}`
                "
                target="_blank"
                @click="trackGA('click', { label: 'images' })"
            >
                <img id="nav-link_Images" src="/img/common/icon_images.svg" />
                Images</a
            >
        </div>
        <div class="navItem yah">
            <a
                id="nav-link_video"
                class="navLink"
                :href="
                    `https://video.search.yahoo.com/yhs/search?p=${
                        enteredQuery ? enteredQuery : ''
                    }&type=${typeTag}`
                "
                target="_blank"
                @click="trackGA('click', { label: 'video' })"
            >
                <img
                    id="nav-link_Video"
                    class="navLink"
                    src="/img/common/icon_video.svg"
                />
                Video</a
            >
        </div>
        <div class="navItem yah">
            <a
                id="nav-link_shopping"
                class="navLink"
                :href="
                    `https://shopping.yahoo.com/search?p=${
                        enteredQuery ? enteredQuery : ''
                    }&type=${typeTag}`
                "
                target="_blank"
                @click="trackGA('click', { label: 'shopping' })"
            >
                <img
                    id="nav-link_Shopping"
                    class="navLink"
                    src="/img/common/icon_shopping.svg"
                />
                Shopping</a
            >
        </div>
        <div class="navItem yah">
            <a
                id="nav-link_news"
                class="navLink"
                :href="
                    `https://news.search.yahoo.com/search?p=${
                        enteredQuery ? enteredQuery : ''
                    }&type=${typeTag}`
                "
                target="_blank"
                @click="trackGA('click', { label: 'news' })"
            >
                <img
                    id="nav-link_News"
                    class="navLink"
                    src="/img/common/icon_news.svg"
                />
                News</a
            >
        </div>
        <div id="yahoo-links-more" class="navItem more dropdown">
            <a id="nav-link_more" class="navLink dropbtn" href="#">
                <img
                    id="nav-link_More"
                    class="navLink"
                    src="/img/common/icon_more.svg"
                />
                More</a
            >
            <div class="dropdown-content">
                <a
                    @click="trackGA('click', { label: 'images' })"
                    :href="
                        `https://images.search.yahoo.com/yhs/search?p=${
                            enteredQuery ? enteredQuery : ''
                        }&type=${typeTag}`
                    "
                    target="_blank"
                    ><img
                        class="moreLink"
                        src="/img/common/icon_images.svg"
                    />Images</a
                >
                <a
                    @click="trackGA('click', { label: 'video' })"
                    :href="
                        `https://video.search.yahoo.com/yhs/search?p=${
                            enteredQuery ? enteredQuery : ''
                        }&type=${typeTag}`
                    "
                    target="_blank"
                    ><img
                        class="moreLink"
                        src="/img/common/icon_video.svg"
                    />Videos</a
                >
                <a
                    @click="trackGA('click', { label: 'shopping' })"
                    :href="
                        `https://shopping.yahoo.com/search?p=${
                            enteredQuery ? enteredQuery : ''
                        }&type=${typeTag}`
                    "
                    target="_blank"
                    ><img
                        class="moreLink"
                        src="/img/common/icon_shopping.svg"
                    />Shopping</a
                >
                <a
                    @click="trackGA('click', { label: 'news' })"
                    :href="
                        `https://news.search.yahoo.com/search?p=${
                            enteredQuery ? enteredQuery : ''
                        }&type=${typeTag}`
                    "
                    target="_blank"
                    ><img
                        class="moreLink"
                        src="/img/common/icon_news.svg"
                    />News</a
                >
            </div>
        </div>
    </div>
</template>

<script>
import { trackGA } from "@/analytics";

export default {
    name: "yahoo-header",
    props: ["query", "enteredQuery", "typeTag"],
    data() {
        return {
            trackGA,
        };
    },
    mounted() {},
    watch: {
        currentQuery(nv) {
            this.query = nv;
        },
    },
};
</script>

<style>
/* header nav */
.headerNav {
    grid-column-start: 2;
    display: inline-flex;
    padding-top: 0px;
    margin-left: 120px;
}
.navItem,
.navItem.yah,
.navItem.more {
    display: inline-flex;
    padding: 0px 8px 8px 2px;
    cursor: pointer;
    font-size: 0.75em;
    margin-right: 15px;
    transition: 0.2s ease;
}

.navItem.more {
    display: none;
}

.navItem.more img {
    margin: 0;
    height: 16px;
    width: 10px;
    box-sizing: border-box;
}

a#nav-link_more.navLink {
    padding-top: 12px;
}

@media only screen and (max-width: 1060px) {
    .navItem {
        margin-right: 7px;
    }
}

/*******USE THIS RESPONSIVE SIZING WHEN PIN MODAL IS ACTIVE*******/
@media only screen and (max-width: 780px) {
    .navItem.yah {
        display: none;
    }

    .navItem.more {
        display: inline-flex;
    }
}
/***********/

.navItem img {
    height: 21px;
    width: 21px;
    margin: 0px 4px 0px 0px;
    position: relative;
    top: 5px;
}

.navItem a {
    text-decoration: none;
    color: #3d3d3d;
    cursor: pointer;
    padding: 7px 0px 7px 5px;
}

.activeNavItem {
    border-bottom: 2px solid #0857e8;
}

/***dropdown**/

.dropdown {
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 38px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    min-width: 125px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 14px;
    text-decoration: none;
    display: block;
    display: flex;
    flex-direction: row;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.navItem.more .moreLink {
    width: 15px;
    height: auto;
    margin-right: 8px;
    box-sizing: border-box;
    margin-top: -10px;
}
.header-links {
    display: block;
    clear: both;
    width: 50vw;
    text-align: left;
    margin-left: 10vw;
}
</style>
